import { isNil } from "lodash";
// import throttle from "lodash.throttle";
import React, { useEffect, useState } from "react";
// import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
// import { calculateFunding } from "../../../api/calculator";
import { updateFundingApplication } from "../../../api/funding-application";
import checkmarkIcon from "../../../assets/icons/circle_checkmark.svg";
import bannerIcon from "../../../assets/images/dashboard-banner-success.svg";
import {
  Box,
  Button,
  Footer,
  Loader,
  // Slider,
} from "../../../components/common";
import { currencyFormatter } from "../../../helpers/currencyFormat";
import { useGetMe } from "../../../hooks";
import "./Dashboard.scss";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
// import useGetResource from "../../../hooks/useGetResource/useGetResource";
import NewDashboard from "./NewDashboard";
import {FundingApplicationStepper} from "../../funding-application";

const DeclinedMessage = ({ onClick }) => {
  return (
    <div className="flex-column justify-center align-center">
      <h1 style={{ textAlign: "center", fontWeight: 500 }}>
        {/* Stay tuned we’ll be adding more schools soon. */}
        Unfortunately, we may not be able to proceed forward with your
        application at this time.
      </h1>
      {/* <div>
        Someone from our team will reach out to you shortly to give you further
        information.
      </div> */}
      <Button
        label="Back to home page"
        style={{ marginTop: "2rem" }}
        onClick={onClick}
      />
    </div>
  );
};

// const PreApprovedMessage = ({ onClick }) => {
//   return (
//       <div className="flex-column justify-center align-center">
//         <h1 style={{ textAlign: "center", fontWeight: 500 }}>
//           {/* Stay tuned we’ll be adding more schools soon. */}
//           Congratulations, you have been pre-approved.
//           <br />
//           Someone from YELO will contact you shortly to discuss next steps.
//         </h1>
//         {/* <div>
//         Someone from our team will reach out to you shortly to give you further
//         information.
//       </div> */}
//         <Button
//             label="Back to home page"
//             style={{ marginTop: "2rem" }}
//             onClick={onClick}
//         />
//       </div>
//   );
// };

const NoApplicationsMessage = ({ onClick }) => {
  return (
    <div
      className="flex-column justify-center align-center"
      style={{ marginTop: "10rem" }}
    >
      <h1 style={{ textAlign: "center", fontWeight: 500 }}>
        You have no applications.
        <br /> Would you like to start a new one?
      </h1>
      <Button
        label="Start Application"
        style={{ marginTop: "2rem" }}
        onClick={onClick}
      />
    </div>
  );
};

const Dashboard = () => {
  const { width, height } = {
    with: window.innerWidth || 300,
    height: window.innerHeight || 200,
  }; //useWindowSize();
  const [runConfetti, setRunConfetti] = useState(false);
  const [numberOfConfetti, setNumberOfConfetti] = useState(200);
  const [confettiOpacity, setConfettiOpacity] = useState(1);
  const confettiDuration = 3000;

  const navigate = useNavigate();
  const { data: me, isLoading, isRefetching, refetch } = useGetMe();
  const { fundingApplications, applicable } = me || {};
  const latestApplication = fundingApplications?.[0];
  const {
    maxFundedAmount,
    totalCosts,
    effectiveTerm,
    incomeRate,
    averageMonthlyPayment,
    apr,
  } = latestApplication || {};
  const fundingApplicationId = latestApplication?.id;
  const latestApplicationState = latestApplication?.currentState;

  // const applicable = (me?.applicable && false) || false;
  // const isPreApproved = latestApplication?.currentState === "approved" || false

  // const [salary, setSalary] = useState();
  // const [calculations, setCalculations] = useState({});

  // const { apr, maxMonthlyPayment, totalCosts } = calculations;

  // const incomeRate = useMemo(() => {
  //   return (0.003 * amount) / 1000;
  // }, [amount]); // percentage

  // const handleGrossIncomeUpdate = useMemo(
  //   () =>
  //     throttle(async (value) => {
  //       await calculateFunding({
  //         startingSalary: value,
  //         fundingApplicationId: fundingApplicationId,
  //       }).then(({ data }) => {
  //         setCalculations(data);
  //       });
  //     }, 500),
  //   [fundingApplicationId]
  // );

  // const handleSliderChange = async (e) => {
  //   const value = e.target.value;
  //   setSalary(value);
  //   handleGrossIncomeUpdate(value);
  // };

  const handleRedirect = async () => {
    if (latestApplicationState === "stepOneFinished") {
      const data = {
        transition: "to_stepTwoFinished",
        offerAccepted: true,
      };

      try {
        await updateFundingApplication({ data, id: fundingApplicationId }).then(
          () => {
            refetch().then(() => navigate("/funding-application"));
          }
        );
      } catch (e) {
        console.log("Error: ", e);
      }
    } else {
      navigate("/funding-application");
    }
  };

  useEffect(() => {
    refetch();
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    document.body.classList.add("dashboard");

    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []);

  useEffect(() => {
    refetch();

    if (!fundingApplications?.length) {
      document.body.classList.add("no-application");
    } else if (!applicable && fundingApplications?.length) {
      document.body.classList.add("declined");
    }

    return () => {
      if (!fundingApplications?.length) {
        document.body.classList.remove("no-application");
      } else if (!applicable) {
        document.body.classList.remove("declined");
      }
    };
  }, [applicable]);

  // useEffect(() => {
  //   if (fundingApplicationId && startingSalary) {
  //     (async () => {
  //       setSalary(startingSalary);
  //       await calculateFunding({
  //         startingSalary: startingSalary,
  //         fundingApplicationId: fundingApplicationId,
  //       }).then(({ data }) => {
  //         setCalculations(data);
  //       });
  //     })();
  //   }
  // }, [fundingApplicationId, startingSalary]);

  useEffect(() => {
    if (applicable && fundingApplications?.length) {
      setRunConfetti(true);

      setTimeout(() => {
        setNumberOfConfetti(0);
      }, confettiDuration);

      setTimeout(() => {
        setRunConfetti(false);
        setConfettiOpacity(0);
      }, confettiDuration * 3);
    }
  }, [applicable, fundingApplications?.length]);

  // const handleConfettiComplete = () => {
  //   setNumberOfConfetti(0);
  //   console.log("confetti complete", numberOfConfetti);
  // };


  // const loadNewDashboard = latestApplicationState !== "declined";
  const appState = latestApplicationState ? latestApplicationState : '';
  let newDashboard = false;
  switch (appState) {
    case "declined":
    case "terminated":
      newDashboard = true;
      break;
    default:
      newDashboard = false;
      break;
  }

  if (localStorage.getItem('old_dashboard')) {
    newDashboard = false;
  }

  const loadNewDashboard = newDashboard;
  // console.log('latestApplicationState', latestApplicationState)

  return !me || isLoading || isRefetching ? (
    <Loader />
  ) : (
    <>
      {(applicable && false === latestApplicationState.toLowerCase().includes("declined")) && !loadNewDashboard && <FundingApplicationStepper activeStep={latestApplicationState} />}
      {/*<FundingApplicationStepper activeStep='stepOneFinished' />*/}
      <main
        className={`dashboard${
          !fundingApplications?.length
            ? " no-application"
            : !applicable
            ? " declined"
            : ""
        }`}
      >
        <Box className="banner">
          <h2 className="banner__message">
            {!fundingApplications?.length ? (
              `Welcome, ${me?.firstname} ${me?.lastname}!`
            ) : !applicable ? (
              `Thank you for applying, ${me?.firstname}!`
            ) : (
              <>Congratulations, {me?.firstname}!</>
            )}
          </h2>
          <div className="decorations">
            <div className="d1 light-blue circle"></div>
            <div className="d2 light-blue outline circle"></div>
            <div className="d3 yellow circle"></div>
            <div className="d4 light-blue circle"></div>
            <div className="d5 yellow circle"></div>
            <div className="d6 light-blue circle"></div>
            <div className="d7 yellow outline circle"></div>
          </div>
          {fundingApplications?.length && applicable ? (
            <img className="visual" src={bannerIcon} alt="" />
          ) : (
            ""
          )}
        </Box>
        {fundingApplications?.length && applicable ? (
          <h1>Pre-Approved Offer</h1>
        ) : (
          ""
        )}
        {/* NO APPLICATIONS */}
        {!fundingApplications?.length ? (
          <NoApplicationsMessage
            onClick={() => navigate("/funding-application")}
          />
        ) : // DECLINED
        (!applicable && latestApplicationState.toLowerCase().includes("declined") && !loadNewDashboard) ? (
          <DeclinedMessage onClick={() => navigate("/")} />
        ) : ( loadNewDashboard ? <NewDashboard latestApplication={latestApplication} me={me} latestApplicationState={latestApplicationState} /> :
          <Box className="body">
            <div className="left">
              <Box className="amount">
                <h2>Pre-Approved Funding Amount</h2>
                {maxFundedAmount && (
                  <h3>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(maxFundedAmount)}
                  </h3>
                )}
              </Box>
              {/* <Box className="starting-salary">
                <h2>Starting salary</h2>
                <h3>{currencyFormatter(salary)}</h3>
                {salary && latestApplicationState !== "terminated" && (
                  <Slider
                    value={salary}
                    onChange={handleSliderChange}
                    min={30000}
                    max={150000}
                    step={1000}
                  />
                )}
              </Box> */}
            </div>
            <Box className="right">
              <div className="flex-column justify-center table">
                <div className="flex justify-between align-center row">
                  <div className="flex">
                    <div>Maximum Number of Years</div>
                    <Tooltip
                      title="This excludes any payment relief pauses"
                      enterTouchDelay={0}
                    >
                      <HelpOutlineIcon fontSize={"small"} />
                    </Tooltip>
                  </div>
                  <div style={{ fontWeight: 500 }}>6</div>
                </div>
                <div className="flex justify-between align-center row">
                  <div className="flex">
                    <div>Repayment Cap</div>
                    <Tooltip
                      title="This is the maximum amount you will ever repay "
                      enterTouchDelay={0}
                    >
                      <HelpOutlineIcon fontSize={"small"} />
                    </Tooltip>
                  </div>
                  <div style={{ fontWeight: 500 }}>
                    {currencyFormatter(1.9 * maxFundedAmount)}
                  </div>
                </div>
                {!isNil(apr)? (
                  <div className="flex justify-between align-center row">
                    <div className="flex">
                      <div>Expected APR</div>
                      <Tooltip
                        title="This is the expected APR assuming that your income remains constant and there are no payment pauses"
                        enterTouchDelay={0}
                      >
                        <HelpOutlineIcon fontSize={"small"} />
                      </Tooltip>
                    </div>
                    <div style={{ fontWeight: 500 }}>
                      {Number(apr) === 0
                        ? "-"
                        : (Number(apr) * 100).toFixed(2) + "%"
                      }
                    </div>
                  </div>
                )
                : null
                }
                <div className="flex justify-between align-center row">
                  <div className="flex">
                    <div>Maximum APR</div>
                    <Tooltip
                      title="Your total APR will never be greater than 12.90%"
                      enterTouchDelay={0}
                    >
                      <HelpOutlineIcon fontSize={"small"} />
                    </Tooltip>
                  </div>
                  <div style={{ fontWeight: 500 }}>12.90%</div>
                </div>
                {!isNil(incomeRate) && (
                  <div className="flex justify-between align-center row">
                    <div className="flex">
                      <div>Income Share</div>
                      <Tooltip
                        title="Your monthly payments equal this income share % times your monthly income"
                        enterTouchDelay={0}
                      >
                        <HelpOutlineIcon fontSize={"small"} />
                      </Tooltip>
                    </div>
                    <div style={{ fontWeight: 500 }}>
                      {((0.75 * maxFundedAmount) / 1000).toFixed(2)}%
                    </div>
                  </div>
                )}
                <div className="flex justify-between align-center row">
                  <div className="flex">
                    <div>Minimum Income Threshold</div>
                    <Tooltip
                      title="If you earn less than $30,000, payments will be paused"
                      enterTouchDelay={0}
                    >
                      <HelpOutlineIcon fontSize={"small"} />
                    </Tooltip>
                  </div>
                  <div style={{ fontWeight: 500 }}>
                    {currencyFormatter(30000)}/year
                  </div>
                </div>
                {/* {!isNil(maxMonthlyPayment) && (
                  <div className="flex justify-between align-center row">
                    <div>Maximum monthly payment</div>
                    <div style={{ fontWeight: 500 }}>
                      {currencyFormatter(maxMonthlyPayment)}
                    </div>
                  </div>
                )} */}

                {!isNil(totalCosts) && !isNil(effectiveTerm) && (
                  <div className="flex justify-between align-center row">
                    <div className="flex">
                      <div>Monthly Payment</div>
                      <Tooltip
                        title="This is the expected monthly payment based on the expected income as provided by the Department of Education College Scorecard"
                        enterTouchDelay={0}
                      >
                        <HelpOutlineIcon fontSize={"small"} />
                      </Tooltip>
                    </div>
                    <div style={{ fontWeight: 500 }}>
                      {/* {currencyFormatter(totalCosts / effectiveTerm)} */}
                      {currencyFormatter(averageMonthlyPayment, 2)}
                    </div>
                  </div>
                )}
                {!isNil(totalCosts) && (
                  <div className="flex justify-between align-center row">
                    <div className="flex">
                      <div>Total Costs</div>
                      <Tooltip
                        title="This is the total cost of the ICF including the disbursement fees"
                        enterTouchDelay={0}
                      >
                        <HelpOutlineIcon fontSize={"small"} />
                      </Tooltip>
                    </div>
                    <div style={{ fontWeight: 500 }}>
                      {currencyFormatter(totalCosts)}
                    </div>
                  </div>
                )}
                {latestApplicationState !== "terminated" ? (
                  <div className="flex wrap justify-between align-center footer">
                    <div className="text">
                      Before applying for an ICF, you should ensure you have
                      fully utilized federal student loan options and leverage
                      all scholarships and grant options.
                      Please note that pre-approval does not guarantee final approval. 
                      Each application undergoes a comprehensive evaluation process before a final decision is made.
                    </div>
                    <Button
                      label={`Proceed with your Offer`}
                      onClick={handleRedirect}
                      disabled={isLoading || isRefetching}
                      className="primary xl"
                      />
                  </div>
                ) : (
                  <div
                    className="flex align-center justify-center"
                    style={{ marginTop: "2rem", gap: "1rem" }}
                  >
                    <img alt="" src={checkmarkIcon} />
                    <div>You are all set! Thanks for applying.<br />Someone from our team will email you shortly.</div>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        )}
      </main>
      {fundingApplications?.length > 0 && applicable && (
        <Confetti
          width={width}
          height={height}
          colors={[
            "#203864",
            "#657df1",
            "#f9df1c",
            "#fae86b",
            "#fef8d4",
            "#fd746b",
            "#7988A2",
            "#7988A2",
          ]}
          run={runConfetti}
          numberOfPieces={numberOfConfetti}
          tweenDuration={confettiDuration}
          opacity={confettiOpacity}
        />
      )}
      <Footer />
    </>
  );
};

export default Dashboard;
