import { currencyFormatter } from "../../../helpers/currencyFormat";
import { formatDate, getDateYear } from "../../../helpers/formatDate";
import checkmarkIcon from "../../../assets/icons/circle_checkmark.svg";
import React, { useState } from "react";
import "./dashboard_data.scss";
import { isNil } from "lodash";

const NewDashboard = ({ latestApplication, me, latestApplicationState }) => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  return (
    <section className="dashboard-data-section">
      <div className="section__innerWrapper">
        <div className="dashboard-data-container">
          <div className="dashboard-column">
            <div className="column-title">
              Personal information{" "}
              <span
                className={`open-close-button ${isOpen1 ? "opened" : ""}`}
                onClick={() => setIsOpen1(!isOpen1)}
              ></span>
            </div>
            <ul className={isOpen1 ? "open" : ""}>
              <li>
                <span className="label">NAME</span>
                <span className="data">{`${me?.firstname} ${me?.lastname}`}</span>
              </li>
              <li>
                <span className="label">E-mail</span>
                <span className="data">{`${me?.email}`}</span>
              </li>
              <li>
                <span className="label">Phone</span>
                <span className="data">{`${me?.phone}`}</span>
              </li>
              <li>
                <span className="label">Birth Date</span>
                <span className="data">
                  {`${formatDate(latestApplication?.birthDate)}`}
                </span>
              </li>
              <li>
                <span className="label">Citizenship</span>
                <span className="data">
                  {`${latestApplication?.citizenshipStatus?.type}`}
                </span>
              </li>
              <li>
                <span className="label">LinkedIn URL</span>
                <span className="data">
                  {`${latestApplication?.linkedInUrl}`}
                </span>
              </li>
              <li>
                <span className="label">How did you hear about us?</span>
                <span className="data">{`${me?.how}`}</span>
              </li>
            </ul>
          </div>

          <div className="dashboard-column">
            <div className="column-title">
              Education{" "}
              <span
                className={`open-close-button ${isOpen2 ? "opened" : ""}`}
                onClick={() => setIsOpen2(!isOpen2)}
              ></span>
            </div>
            <ul className={isOpen2 ? "open" : ""}>
              <li>
                <span className="label">INSTITUTION</span>
                <span className="data">
                  {latestApplication?.institution?.name}
                </span>
              </li>
              <li>
                <span className="label">State</span>
                <span className="data">{latestApplication?.state?.name}</span>
              </li>
              <li>
                <span className="label">Enrollment</span>
                <span className="data">
                  {latestApplication?.enrollmentStatus?.type}
                </span>
              </li>
              <li>
                <span className="label">Degree</span>
                <span className="data">{latestApplication?.degree?.type}</span>
              </li>
              <li>
                <span className="label">School Year</span>
                <span className="data">
                  {latestApplication?.schoolYear?.type}
                </span>
              </li>
              <li>
                <span className="label">Graduation</span>
                <span className="data">
                  {getDateYear(latestApplication?.graduation)}
                </span>
              </li>
              <li>
                <span className="label">Major</span>
                <span className="data">{latestApplication?.major?.type}</span>
              </li>
              <li>
                <span className="label">GPA</span>
                <span className="data">{latestApplication?.gpa}</span>
              </li>
            </ul>
          </div>

          <div className="dashboard-column no-border">
            <div className="column-title">
              Financial Details{" "}
              <span
                className={`open-close-button ${isOpen3 ? "opened" : ""}`}
                onClick={() => setIsOpen3(!isOpen3)}
              ></span>
            </div>
            <ul className={isOpen3 ? "open" : ""}>
              <li>
                <span className="label">Requested amount</span>
                <span className="data">
                  {currencyFormatter(latestApplication?.amount)}
                </span>
              </li>
              <li>
                <span className="label">Pre-approved amount</span>
                <span className="data">
                  {currencyFormatter(latestApplication?.maxFundedAmount)}
                </span>
              </li>
              {latestApplicationState !== "preApproved" ? (
                <>
                  <li>
                    <span className="label">Maximum number of years</span>
                    <span className="data">6</span>
                  </li>
                  <li>
                    <span className="label">Repayment cap</span>
                    <span className="data">
                      {currencyFormatter(
                        1.9 * latestApplication?.maxFundedAmount
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="label">Expected APR</span>
                    <span className="data">
                      {Number(latestApplication?.apr) === 0
                        ? "-"
                        : (Number(latestApplication?.apr) * 100).toFixed(2) +
                          "%"}
                    </span>
                  </li>
                  <li>
                    <span className="label">Maximum APR</span>
                    <span className="data">12.90%</span>
                  </li>
                  <li>
                    <span className="label">Income Share</span>
                    <span className="data">
                      {(
                        (0.75 * latestApplication?.maxFundedAmount) /
                        1000
                      ).toFixed(2)}
                      %
                    </span>
                  </li>
                  <li>
                    <span className="label">Minimum Income Threshold</span>
                    <span className="data">
                      {currencyFormatter(30000)}/year
                    </span>
                  </li>
                  <li>
                    <span className="label">Monthly Payment</span>
                    <span className="data">
                      {currencyFormatter(
                        latestApplication?.averageMonthlyPayment,
                        2
                      )}
                    </span>
                  </li>
                  {!isNil(latestApplication?.totalCosts) && (
                    <li>
                      <span className="label">Total Costs</span>
                      <span className="data">
                        {currencyFormatter(latestApplication?.totalCosts)}
                      </span>
                    </li>
                  )}
                </>
              ) : null}
            </ul>
          </div>
          <div className="dashboard-bottom-row">
            <p>
              <img
                alt=""
                src={checkmarkIcon}
              />{" "}
              You are all set! Thanks for applying.
              <br />
              Someone from our team will email you shortly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewDashboard;
